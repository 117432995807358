<template>
    <div class="reminddetails-main">
      	<div class="banner-container">
			<p class="title PangMenZhengDao">{{$t("remiind.receiveAlerts")}}</p>
			<p class="desc">
				{{$t("remiind.desc")}}
			</p>
		</div>
		<div class="sect-container">
			<div class="sec-item">
				<p class="lable">
					{{$t("remiind.remindTime")}}:
				</p>
				<p class="value">{{getLocalMinutesTime(details.msgCdt)}}</p>
			</div>
			<div class="sec-item">
				<p class="lable">
					{{$t("remiind.remindcontent")}}:
				</p>
				<p class="value">{{details.msgContent}}</p>
			</div>
		</div>
    </div>
</template>

<script>
import { remindDetails } from "@/api/index";
import { getLocalMinutesTime } from "@/utils/date";

export default {
	data () {
		return {
			followupNodeActionId:this.$route.query.followupNodeActionId,
			details:{},
			getLocalMinutesTime
		}
	},
	created(){
		this.getDetails()
		document.title = this.$t("remiind.name")
	},
	methods:{
		getDetails(){
			const toast = this.$Toast.loading({
				duration: 0, // 持续展示 toast
				forbidClick: true,
				message: this.$t("message.loadingTxt")
			});
			remindDetails({followupNodeActionId:this.followupNodeActionId}).then(res=>{
				toast.close();
				console.log(res);
				if(res.data.errorCode == 0){
					this.details = res.data.data;
				}
			})
		}
	}
}
</script>

<style lang="scss" scoped>
// import
@import url("~@/assets/iconfont/iconfont.css");
.reminddetails-main{
	min-height: 100vh;
	background-color: #F5F6FA;
	background-image: url("~@/assets/img/remind-back.png") ;
	background-size: 100% 7.8rem;
	background-repeat: no-repeat;
	// box-sizing: border-box;
	// padding: 0 0.6rem;
	.banner-container{
		height: 5.4rem;
		padding-left: 6.4rem;
		padding-top: 1.6rem;
		background-image: url("~@/assets/img/remind-back2.png");
		background-repeat: no-repeat;
		background-size: 6.3rem 4rem;
		background-position-y: 70%;
		box-sizing: border-box;
		.title{
			font-size: 1.25rem;
			font-family: "PangMenZhengDao";
			color: #FFFFFF;
			line-height: 1.52rem;
			letter-spacing: 0.24rem;
			font-weight: 500;
			-webkit-background-clip: text;
			// -webkit-text-fill-color: transparent;
		}
		.desc{
			font-size: 0.52rem;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #FFFFFF;
			line-height: 0.74rem;
			margin-top: 0.15rem;
			letter-spacing: 0.04rem;
		}
	}
	.sect-container{
		margin: 0 0.6rem;
		padding: 0.8rem;
		background: #FFFFFF;
		min-height: 8rem;
		border-radius: 0.52rem;
		.sec-item{
			margin-bottom: 0.5rem;
			.lable{
				font-size: 0.64rem;
				padding-left: 0.4rem;
				font-weight: bold;
				position: relative;
				&::after{
					position: absolute;
					content: ' ';
					height: 80%;
					width: 0.16rem;
					background: linear-gradient(180deg, #378BFD 0%, #88C5FD 100%);
					border-radius: 0.08rem;
					left: 0;
					top: 50%;
					transform: translate(0,-40%);
				}
			}
			.value{
				font-size: 0.64rem;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #333333;
				margin-top: 0.1rem;
			}
		}
	}

// remind-back2.png
// 
}
</style>